import { ReactComponent as ArrowLeft } from './ArrowLeft.svg'
import { ReactComponent as ArrowRight } from './ArrowRight.svg'
import { ReactComponent as Search } from './Search.svg'
import { ReactComponent as Logout } from './Logout.svg'
import { ReactComponent as Edit } from './Edit.svg'
import { ReactComponent as Locked } from './Locked.svg'
import { ReactComponent as Unlocked } from './Unlocked.svg'
import { ReactComponent as ArrowDownPrimary } from './ArrowDownPrimary.svg'
import { ReactComponent as ArrowUpPrimary } from './ArrowUpPrimary.svg'
import { ReactComponent as ArrowDownSecondary } from './ArrowDownSecondary.svg'
import { ReactComponent as ArrowUpSecondary } from './ArrowUpSecondary.svg'
import { ReactComponent as CardTail } from './CardTail.svg'
import { ReactComponent as Close } from './Close.svg'
import { ReactComponent as ReportClose } from './ReportClose.svg'
import { ReactComponent as CloseDark } from './CloseDark.svg'
import { ReactComponent as LeftArrowPrimary } from 'assets/images/Controls/LeftArrowPrimary.svg'
import { ReactComponent as RightArrowPrimary } from 'assets/images/Controls/RightArrowPrimary.svg'
import { ReactComponent as Calendar } from 'assets/images/Controls/Calendar.svg'
import { ReactComponent as Excel } from './Excel.svg'
import { ReactComponent as Eye } from './Eye.svg'
import { ReactComponent as Check } from './Check.svg'
import { ReactComponent as CheckBox } from './CheckBox.svg'
import { ReactComponent as UncheckedBox } from './UncheckedBox.svg'
import { ReactComponent as DoubleCheck } from './DoubleCheck.svg'
import { ReactComponent as Clock } from './Clock.svg'
import { ReactComponent as Update } from './Update.svg'
import { ReactComponent as Operator } from './Operator.svg'
import { ReactComponent as WhatsApp } from './WhatsApp.svg'
import { ReactComponent as Call } from './Call.svg'
import { ReactComponent as Delete } from './Delete.svg'
import { ReactComponent as Plus } from './Plus.svg'
import { ReactComponent as Declined } from './Declined.svg'
import { ReactComponent as Submitted } from './Submitted.svg'
import { ReactComponent as Approved } from './Approved.svg'
import { ReactComponent as Expired } from './Expired.svg'
import { ReactComponent as Resubmission } from './Resubmission.svg'
import { ReactComponent as Trailer } from './Trailer.svg'
import { ReactComponent as Lock } from './Lock.svg'
import { ReactComponent as Unlock } from './Unlock.svg'
import { ReactComponent as Notifications } from './Notifications.svg'
import { ReactComponent as CloseNotificationDrawer } from './CloseNotificationDrawer.svg'
import { ReactComponent as Tool } from './Tool.svg'
import { ReactComponent as AddedFile } from './AddedFile.svg'
import { ReactComponent as Download } from './Download.svg'
import { ReactComponent as Filter } from './Filter.svg'
import { ReactComponent as Send } from './Send.svg'
import { ReactComponent as Activate } from './Activate.svg'
import { ReactComponent as Deactivate } from './Deactivate.svg'
import { ReactComponent as QR } from './QR.svg'
import { ReactComponent as Reset } from './Reset.svg'
import { ReactComponent as Battery } from './Battery.svg'

export const Controls = {
  ArrowLeft,
  ArrowRight,
  Search,
  Logout,
  Edit,
  Locked,
  Unlocked,
  ArrowDownPrimary,
  ArrowUpPrimary,
  ArrowDownSecondary,
  ArrowUpSecondary,
  CardTail,
  Close,
  CloseDark,
  LeftArrowPrimary,
  RightArrowPrimary,
  Excel,
  Calendar,
  Eye,
  Check,
  CheckBox,
  UncheckedBox,
  DoubleCheck,
  Clock,
  Update,
  Operator,
  WhatsApp,
  Call,
  ReportClose,
  Delete,
  Plus,
  Declined,
  Submitted,
  Approved,
  Expired,
  Resubmission,
  Trailer,
  Lock,
  Unlock,
  Notifications,
  CloseNotificationDrawer,
  Tool,
  AddedFile,
  Download,
  Filter,
  Send,
  Activate,
  Deactivate,
  QR,
  Reset,
  Battery,
}
