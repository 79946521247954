import { useCallback, useEffect, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Controls } from 'assets/images/Controls'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_CurrentTrailerBreakdowns } from 'redux/modules/currentTrailerBreakdowns/Actions'
import { TCurrentTrailerBreakdown } from 'redux/modules/currentTrailerBreakdowns/TReducer'
import { useAuthRights } from 'ui/hooks/useAuthRights'
import { TColumns } from 'ui/shared/Table/Table'
import { ITableIconCell } from 'ui/shared/TableIconCell/TableIconCell'
import { TTableCheckboxCellProps } from 'ui/shared/TableCheckboxCell/TableCheckboxCell'
import DateConverter from 'utils/helpers/date'

type TModalProps = {
  trailerId: number
}

export interface IBreakdownsTableData {
  userId: number
  type: string
  comment: string
  action: ITableIconCell
  isConfirmed: TTableCheckboxCellProps
  isResolved: TTableCheckboxCellProps
  isRejected: TTableCheckboxCellProps
  date: string
}

const columns: TColumns = [
  {
    label: 'User ID',
    dataKey: 'userId',
    width: 100,
    minWidth: 70,
  },
  {
    label: 'Type of breakage',
    dataKey: 'type',
    width: 220,
    minWidth: 150,
  },
  {
    label: 'Comment',
    dataKey: 'comment',
    width: 450,
    minWidth: 150,
    disableSort: true,
  },
  {
    label: 'Photo',
    dataKey: 'action',
    width: 70,
    minWidth: 50,
    disableSort: true,
    componentType: 'action',
  },
  {
    label: 'Сonfirmed',
    dataKey: 'isConfirmed',
    width: 120,
    minWidth: 100,
    componentType: 'checkbox',
  },
  {
    label: 'Resolved',
    dataKey: 'isResolved',
    width: 120,
    minWidth: 100,
    componentType: 'checkbox',
  },
  {
    label: 'Rejected',
    dataKey: 'isRejected',
    width: 120,
    minWidth: 100,
    componentType: 'checkbox',
  },
  {
    label: 'Correction date',
    dataKey: 'date',
    width: 250,
    minWidth: 150,
  },
]

export const useBreakdownHistoryTable = () => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(I18nModal.breakdownHistory)

  const currentTrailerBreakdowns = useSelector(
    ({ currentTrailerBreakdowns }: TState) => currentTrailerBreakdowns.data || [],
  )
  const isLoading = useSelector(({ currentTrailerBreakdowns }: TState) => currentTrailerBreakdowns.isLoading)
  const cdnUrl = useSelector((state: TState) => state.settings.data?.cdnUrl || '')
  const { trailerId } = useSelector(({ modals }: TState) => modals.data as TModalProps)

  const { canTrailerDetailsInspectionEdit } = useAuthRights()

  useEffect(() => {
    dispatch(Actions_CurrentTrailerBreakdowns.saga.getBreakdowns({ id: trailerId }))
    return () => {
      dispatch(Actions_CurrentTrailerBreakdowns.store.clearBreakdowns())
    }
  }, [dispatch])

  const onConfimedClick = useCallback(
    (breakdown: TCurrentTrailerBreakdown, event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.checked
      const data = {
        isConfirmed: newValue,
        isRejected: false,
      }

      dispatch(Actions_CurrentTrailerBreakdowns.saga.updateBrekdown({ id: breakdown.id, data }))
    },
    [dispatch],
  )

  const onResolvedClick = useCallback(
    (breakdown: TCurrentTrailerBreakdown, event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.checked
      const data = {
        isResolved: newValue,
      }

      dispatch(Actions_CurrentTrailerBreakdowns.saga.updateBrekdown({ id: breakdown.id, data }))
    },
    [dispatch],
  )

  const onRejectedClick = useCallback(
    (breakdown: TCurrentTrailerBreakdown, event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.checked
      const data = {
        isConfirmed: false,
        isRejected: newValue,
      }

      dispatch(Actions_CurrentTrailerBreakdowns.saga.updateBrekdown({ id: breakdown.id, data }))
    },
    [dispatch],
  )

  const onImageClick = useCallback(
    (image: string) => {
      if (image) {
        window.open(cdnUrl + image, '_blank')
      }
    },
    [cdnUrl],
  )

  const data = useMemo(
    () =>
      toTableData(
        currentTrailerBreakdowns,
        onConfimedClick,
        onResolvedClick,
        onRejectedClick,
        onImageClick,
        classes,
        t,
        canTrailerDetailsInspectionEdit,
      ),
    [
      currentTrailerBreakdowns,
      onConfimedClick,
      onResolvedClick,
      onRejectedClick,
      onImageClick,
      classes,
      t,
      canTrailerDetailsInspectionEdit,
    ],
  )

  return {
    table: {
      tableClassName: classes.table,
      columns,
      data,
      isLoading,
    },
  }
}

const toTableData = (
  currentTrailerBreakdowns: TCurrentTrailerBreakdown[],
  onConfimedClick: (breakdown: TCurrentTrailerBreakdown, event: React.ChangeEvent<HTMLInputElement>) => void,
  onResolvedClick: (breakdown: TCurrentTrailerBreakdown, event: React.ChangeEvent<HTMLInputElement>) => void,
  onRejectedClick: (breakdown: TCurrentTrailerBreakdown, event: React.ChangeEvent<HTMLInputElement>) => void,
  onImageClick: (image: string) => void,
  classes: any,
  t: TFunction,
  canTrailerDetailsInspectionEdit: boolean,
): IBreakdownsTableData[] =>
  currentTrailerBreakdowns.map((breakdown) => {
    return {
      userId: breakdown.userId,
      type: breakdown.issueType ? t(breakdown.issueType) : '',
      comment: breakdown.description,
      action: {
        Icon: Controls.AddedFile,
        className: breakdown.image ? classes.showPhotoIcon : classes.hidePhotoIcon,
        onClick: () => onImageClick(breakdown.image),
      },
      isConfirmed: {
        value: breakdown.isConfirmed,
        onChange: (event) => onConfimedClick(breakdown, event),
        disabled: !canTrailerDetailsInspectionEdit,
      },
      isResolved: {
        value: breakdown.isResolved,
        onChange: (event) => onResolvedClick(breakdown, event),
        disabled: !canTrailerDetailsInspectionEdit,
      },
      isRejected: {
        value: breakdown.isRejected,
        onChange: (event) => onRejectedClick(breakdown, event),
        disabled: !canTrailerDetailsInspectionEdit,
      },
      date: DateConverter.formatDate(breakdown.date) || '',
    }
  })

const useStyles = makeStyles()({
  table: {
    flex: 1,
  },
  showPhotoIcon: {
    dispaly: 'block',
  },
  hidePhotoIcon: {
    display: 'none',
  },
})
