import { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { useForm } from 'react-hook-form'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'
import { TStations } from 'redux/modules/stations/TReducer'

interface IForm {
  stationId: number
}

type TModalProps = {
  userId: number
  chargeUser: boolean
}

export const useCompleteRent = () => {
  const { t } = useTranslation(I18nModal.completeRent)
  const dispatch = useDispatch()

  const { isLoadingStations, isCompleteRentInProgress, stations, modalProps } = useSelector(
    ({ stations, currentUser, modals }: TState) => ({
      isLoadingStations: stations.isLoading,
      isCompleteRentInProgress: currentUser.isLoading,
      stations: stations.data || [],
      modalProps: modals.data as TModalProps,
    }),
    shallowEqual,
  )

  const { handleSubmit, errors, control } = useForm<IForm>()

  const onSubmit = (formData: IForm) => {
    const data = {
      userId: modalProps.userId,
      chargeUser: modalProps.chargeUser,
      finishStationId: formData.stationId,
    }

    dispatch(Actions_CurrentUser.saga.completeRent(data))
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  const stationsOptions = useMemo(() => toDropdownOption(stations), [stations])

  return {
    t,
    closeModal,
    isLoading: isLoadingStations || isCompleteRentInProgress,
    isCompleteRentInProgress,
    form: {
      control,
      stationsOptions,
      errors,
      handleSubmit: handleSubmit(onSubmit),
    },
  }
}

const toDropdownOption = (stations: TStations) =>
  stations.map(({ station }) => ({
    id: station.id,
    label: station.stationCode,
    value: station.id,
  }))
