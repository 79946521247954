import { createReducer } from '@reduxjs/toolkit'
import { IStateReport } from './TReducer'
import TableFilterReduxAdapter from 'utils/helpers/table'
import DateConverter from 'utils/helpers/date'
import { Actions_report } from './Actions'

const initialState: IStateReport = {
  isLoading: false,
  isAllDataLoaded: false,
  data: null,
  detailedReport: null,
  responseInfo: null,
  filter: {
    ...TableFilterReduxAdapter.DEFAULT_FILTERS,
    sorting: 'vin',
    fromDate: DateConverter.toStartOfDay(new Date()),
    toDate: DateConverter.addDays(DateConverter.toEndOfDay(new Date()), 1),
  },
  isFilterTypeTurnOver: false,
  isDetailed: false,
}

const ReducerReport = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_report.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_report.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_report.store.saveReport, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_report.store.saveDetailedReport, (state, action) => {
      state.detailedReport = action.payload
    })
    .addCase(Actions_report.store.changeFilter, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_report.store.increaseFilterOffset, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_report.store.choosenTurnOver, (state) => {
      state.isFilterTypeTurnOver = true
    })
    .addCase(Actions_report.store.changedTurnOver, (state) => {
      state.isFilterTypeTurnOver = false
    })
    .addCase(Actions_report.store.openDetailedTable, (state) => {
      state.isDetailed = true
    })
    .addCase(Actions_report.store.closeDetailedTable, (state) => {
      state.isDetailed = false
    })
    .addCase(Actions_report.store.reset, () => {
      return initialState
    })
})

export default ReducerReport
