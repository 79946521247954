enum StoreActions {
  IS_LOADER_SHOW = 'CURRENT_USER/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'CURRENT_USER/IS_LOADER_HIDE',
  SAVE_CURRENT_USER = 'CURRENT_USER/SAVE_CURRENT_USER',
  CLEAR_CURRENT_USER = 'CURRENT_USER/CLEAR_CURRENT_USER',
}

enum SagaEvents {
  LOAD_PAGE = 'CURRENT_USER/LOAD_PAGE',
  ACTIVATE_USER = 'CURRENT_USER/ACTIVATE_USER',
  DEACTIVATE_USER = 'CURRENT_USER/DEACTIVATE_USER',
  GET_BILL = 'GET_BILL',
  GET_INVOICE = 'CURRENT_USER/GET_INVOICE',
  REMOVE_BOOKING = 'REMOVE_BOOKING',
  COMPLETE_RENT = 'CURRENT_USER/COMPLETE_RENT',
  VERIFY_USER = 'CURRENT_USER/VERIFY_USER',
  UPDATE_USER_DISCOUNT = 'CURRENT_USER/UPDATE_USER_DISCOUNT',
  REMOVE_USER_DISCOUNT = 'CURRENT_USER/REMOVE_USER_DISCOUNT',
  CONNECT_TO_ORGANIZATION_DISCOUNT = 'CURRENT_USER/CONNECT_TO_ORGANIZATION_DISCOUNT',
  REMOVE_ORGANIZATION_DISCOUNT_CONNECTION = 'CURRENT_USER/REMOVE_ORGANIZATION_DISCOUNT_CONNECTION',
  DELETE_USER = 'CURRENT_USER/DELETE_USER',
}

export const ActionTypes_CurrentUser = {
  StoreActions,
  SagaEvents,
}
