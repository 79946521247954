import React, { FC } from 'react'
import { Shared } from 'ui/shared/index'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'
import { TObject } from 'utils/types'
import { useMultiOrganization } from 'ui/shared/MultiOrganization/MultiOrganization-ViewModel'

interface IProps {
  /**
   * required if you store ids through  redux or useState
   */
  onClick?: (value: number[]) => void
  /**
   * required if you store ids through  redux or useState
   */
  isSelected?: (id: number) => boolean

  /**
   * required if selected ids stored in react-form-hook
   */
  control?: any
  /**
   * required if selected ids stored in react-form-hook
   */
  watch?: (name?: string, defaultValue?: number[]) => number[]
  error?: null | string
  rules?: any
  name?: string

  /**
   * load all options again, because someone can add new one, we should reload everytime
   * default value true
   */
  loadOptions?: boolean
  title?: string
  className?: string
  divider?: boolean
  hideBorder?: boolean
  style?: TObject
  label?: string
  showTitle?: boolean
  type?: 'form' | 'card'
  filterOptions?: (item: TDropdownOption) => boolean
  disabled?: boolean
}

/**
 react-form-hook example
 <Shared.MultiOrganization
  control={control}
  watch={watch}
 />

 state example
 <Shared.MultiOrganization
  onClick={onClick}
  isSelected={isSelected}
 />
 */
const MultiOrganization: FC<IProps> = ({
  className,
  onClick,
  style,
  hideBorder = true,
  divider = true,
  showTitle = true,
  type = 'card',
  label,
  control,
  watch,
  isSelected,
  loadOptions = true,
  error,
  rules,
  name = 'organization',
  title = 'Organizations',
  filterOptions,
  disabled,
}) => {
  const vm = useMultiOrganization({
    isSelected,
    watch,
    loadOptions,
    name,
    filterOptions,
  })
  return (
    <div className={className} style={style}>
      {showTitle && <Shared.CardTitle title={title} divider={divider} />}
      <Shared.Dropdown
        marginTop={type === 'card' ? 16 : 0}
        control={control}
        name={name}
        options={vm.options}
        parentOptions={vm.parentOptions}
        onChange={onClick}
        isSelected={vm.isSelected}
        fullWidth
        hideBorder={hideBorder}
        label={label}
        error={error}
        rules={rules}
        multiple
        value={vm.value}
        defaultValue={[]}
        disabled={disabled}
      />
    </div>
  )
}

export default React.memo(MultiOrganization)
