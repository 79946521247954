import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Rents } from './TActions'
import { TRents, TRentsFilter } from './TReducer'
import { TStoreTemplateWithFilter } from 'redux/config/root-types'

const { StoreActions, SagaEvents } = ActionTypes_Rents

export const Actions_Rents = {
  saga: {
    getRents: createAction(SagaEvents.GET_RENTS),
    loadXlsx: createAction(SagaEvents.LOAD_XLSX),
  },
  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveRents: createAction<TStoreTemplateWithFilter<TRents> & { trailerId?: string; userId?: string }>(
      StoreActions.SAVE_RENTS,
    ),
    changeFilter: createAction<TRentsFilter>(StoreActions.CHANGE_FILTER),
    increaseFilterOffset: createAction<TRentsFilter>(StoreActions.INCREASE_FILTER_OFFSET),
    resetFilter: createAction(StoreActions.RESET_FILTER),
  },
}
