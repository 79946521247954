import axios, { AxiosRequestConfig } from 'axios'
import { TResponseModel, TStoreTemplate } from 'redux/config/root-types'
import { DataConverter } from './dataConverter'
import queryString from 'query-string'
import config from 'utils/conf/config'
import FileSaver from 'file-saver'
import { getResponseFileName } from './utils'

const { convertToStoreModel } = DataConverter()

export const RequestMethods = () => {
  const API_URL = config.API_URL

  async function post<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<TStoreTemplate<T>> {
    const response = await axios.post(API_URL + url, data, config)
    return convertToStoreModel(response.data as TResponseModel<T>)
  }

  async function get<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<TStoreTemplate<T>> {
    let _url = API_URL + url
    if (data) {
      _url = `${_url}?${queryString.stringify(data)}`
    }
    const response = await axios.get(_url, config)
    return convertToStoreModel(response.data as TResponseModel<T>)
  }

  async function put<T>(url: string, data?: any): Promise<TStoreTemplate<T>> {
    const response = await axios.put(API_URL + url, data)
    return convertToStoreModel(response.data as TResponseModel<T>)
  }

  async function remove<T>(url: string): Promise<TStoreTemplate<T>> {
    const response = await axios.delete(API_URL + url)
    return convertToStoreModel(response.data as TResponseModel<T>)
  }

  async function download(url: string, data?: any, config?: AxiosRequestConfig, fileName?: string) {
    let _url = API_URL + url
    if (data) {
      _url = `${_url}?${queryString.stringify(data)}`
    }
    const response = await axios.get(_url, config)

    const responseFileName = getResponseFileName(response)

    const blob = new Blob([response.data])

    FileSaver.saveAs(blob, fileName || responseFileName)
  }

  return {
    post,
    get,
    put,
    remove,
    download,
  }
}
