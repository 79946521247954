enum StoreActions {
  IS_LOADER_SHOW = 'RENTS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'RENTS/IS_LOADER_HIDE',
  SAVE_RENTS = 'RENTS/SAVE_RENTS',
  CHANGE_FILTER = 'RENTS/CHANGE_FILTER',
  INCREASE_FILTER_OFFSET = 'RENTS/INCREASE_FILTER_OFFSET',
  RESET_FILTER = 'RENTS/RESET_FILTER',
}

enum SagaEvents {
  GET_RENTS = 'RENTS/GET_RENTS',
  LOAD_XLSX = 'RENTS/LOAD_XLSX',
}

export const ActionTypes_Rents = {
  StoreActions,
  SagaEvents,
}
