import { createAction } from '@reduxjs/toolkit'
import { TStoreTemplate } from 'redux/config/root-types'
import { TUser } from 'redux/modules/users/TReducer'
import { ISagaWorker_ActivateUser } from 'redux/sagas/events/users/SagaWorker_ActivateUser'
import { ISagaWorker_CompleteRent } from 'redux/sagas/events/users/SagaWorker_CompleteRent'
import { ISagaWorker_DeactivateUser } from 'redux/sagas/events/users/SagaWorker_DeactivateUser'
import { ISagaWorker_DeleteUser } from 'redux/sagas/events/users/SagaWorker_DeleteUser'
import { ISagaWorker_GetBill } from 'redux/sagas/events/users/SagaWorker_GetBill'
import { ISagaWorker_GetInvoice } from 'redux/sagas/events/users/SagaWorker_GetInvoice'
import { ISagaWorker_RemoveBooking } from 'redux/sagas/events/users/SagaWorker_RemoveBooking'
import { ISagaWorker_VerifyUser } from 'redux/sagas/events/users/SagaWorker_VerifyUser'
import { ISagaWorker_UserDetails } from 'redux/sagas/pages/SagaPage_UserDetails'
import { ActionTypes_CurrentUser } from './TAction'
import { ISagaWorker_UpdateUserDiscount } from 'redux/sagas/events/users/SagaWorker_UpdateUserDiscount'
import { ISagaWorker_RemoveUserDiscount } from 'redux/sagas/events/users/SagaWorker_RemoveUserDiscount'
import { ISagaWorker_ConnectToOrganizationDiscount } from 'redux/sagas/events/users/SagaWorker_ConnectToOrganizationDiscount'
import { ISagaWorker_RemoveOrganizationDiscountConnection } from 'redux/sagas/events/users/SagaWorker_RemoveOrganizationDiscountConnection'

const { StoreActions, SagaEvents } = ActionTypes_CurrentUser

export const Actions_CurrentUser = {
  saga: {
    loadPage: createAction<ISagaWorker_UserDetails>(SagaEvents.LOAD_PAGE),
    activateUser: createAction<ISagaWorker_ActivateUser>(SagaEvents.ACTIVATE_USER),
    deactivateUser: createAction<ISagaWorker_DeactivateUser>(SagaEvents.DEACTIVATE_USER),
    getBill: createAction<number>(SagaEvents.GET_BILL),
    getInvoice: createAction<ISagaWorker_GetInvoice>(SagaEvents.GET_INVOICE),
    removeBooking: createAction<ISagaWorker_RemoveBooking>(SagaEvents.REMOVE_BOOKING),
    completeRent: createAction<ISagaWorker_CompleteRent>(SagaEvents.COMPLETE_RENT),
    verifyUser: createAction<ISagaWorker_VerifyUser>(SagaEvents.VERIFY_USER),
    updateUserDiscount: createAction<ISagaWorker_UpdateUserDiscount>(SagaEvents.UPDATE_USER_DISCOUNT),
    removeUserDiscount: createAction<ISagaWorker_RemoveUserDiscount>(SagaEvents.REMOVE_USER_DISCOUNT),
    connectToOrganizationDiscount: createAction<ISagaWorker_ConnectToOrganizationDiscount>(
      SagaEvents.CONNECT_TO_ORGANIZATION_DISCOUNT,
    ),
    removeOrganizationDiscountConnection: createAction<ISagaWorker_RemoveOrganizationDiscountConnection>(
      SagaEvents.REMOVE_ORGANIZATION_DISCOUNT_CONNECTION,
    ),
    deleteUser: createAction<ISagaWorker_DeleteUser>(SagaEvents.DELETE_USER),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveCurrentUser: createAction<TStoreTemplate<TUser>>(StoreActions.SAVE_CURRENT_USER),
    clearCurrentUser: createAction(StoreActions.CLEAR_CURRENT_USER),
  },
}
