import { createReducer } from '@reduxjs/toolkit'
import { IStateTrailers } from './TReducer'
import { Actions_Trailers } from './Actions'

const initialState: IStateTrailers = {
  isLoading: false,
  data: null,
  allTrailers: [],
  responseInfo: null,
}

const ReducerTrailers = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Trailers.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_Trailers.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_Trailers.store.saveTrailers, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_Trailers.store.saveAllTrailers, (state, action) => {
      state.allTrailers = action.payload
    })
    .addCase(Actions_Trailers.store.clearTrailers, (state) => {
      state.data = null
    })
})

export default ReducerTrailers
