enum StoreActions {
  IS_LOADER_SHOW = 'TRAILERS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'TRAILERS/IS_LOADER_HIDE',
  SAVE_TRAILERS = 'TRAILERS/SAVE_TRAILERS',
  SAVE_ALL_TRAILERS = 'TRAILERS/SAVE_ALL_TRAILERS',
  CLEAR_TRAILERS = 'TRAILERS/CLEAR_TRAILERS',
  CHANGE_FILTER = 'TRAILERS/CHANGE_FILTER',
}

enum SagaEvents {
  GET_TRAILERS = 'TRAILERS/GET_TRAILERS',
  LOAD_PAGE = 'TRAILERS/LOAD_PAGE',
  GET_ALL_TRAILERS = 'TRAILERS/GET_ALL_TRAILERS',
  UPDATE_TRAILERS = 'TRAILERS/UPDATE_TRAILERS',
  LOAD_TRAILERS_LIST_PAGE = 'TRAILERS/LOAD_TRAILERS_LIST_PAGE',
  GET_TRAILERS_LIST = 'TRAILERS/GET_TRAILERS_LIST',
  DELETE_TRAILER_FROM_TRAILERS_LIST = 'TRAILERS/DELETE_TRAILER_FROM_TRAILERS_LIST',
  LOAD_TABLE_XLSX = 'TRAILERS/LOAD_TABLE_XLSX',
}

export const ActionTypes_Trailers = {
  SagaEvents,
  StoreActions,
}
