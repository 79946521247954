import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Shared } from 'ui/shared'
import { useParkingDelete } from 'ui/modals/ParkingDelete/ParkingDelete-ViewModel'

export const ParkingDelete: FC = () => {
  const { onSubmit, onCancel } = useParkingDelete()

  return (
    <Shared.Card style={{ width: 375 }}>
      <Shared.CardTitle title={'Parking delete'} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Shared.Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{'You sure want delete parking'}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Shared.Button title={'No'} onClick={onCancel} color={'primary'} />
        </Grid>
        <Grid item xs={6}>
          <Shared.Button width={'100%'} title={'Yes'} onClick={onSubmit} color={'secondary'} />
        </Grid>
      </Grid>
    </Shared.Card>
  )
}
