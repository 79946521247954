import { all, takeEvery } from 'redux-saga/effects'
import {
  SagaPage_ParkingDetails,
  SagaPage_Parkings,
  SagaPage_Report,
  SagaPage_TrailersMap,
  SagaPage_TrailerDetails,
  SagaPage_TrailerTypes,
  SagaPage_Staff,
  SagaPage_StaffDetails,
  SagaPage_Users,
  SagaPage_UserDetails,
  SagaWorker_Auth,
  SagaWorker_CreateParking,
  SagaWorker_CreateStation,
  SagaWorker_CreateTrailer,
  SagaWorker_CreateTrailerType,
  SagaWorker_GetRents,
  SagaWorker_GetReport,
  SagaWorker_GetDetailedReport,
  SagaWorker_Initial,
  SagaWorker_LoadAllParkings,
  SagaWorker_LoadParkings,
  SagaWorker_LoadTrailerMarkers,
  SagaWorker_Organizations,
  SagaWorker_UpdateParking,
  SagaWorker_UpdateStation,
  SagaWorker_UpdateTrailer,
  SagaWorker_UpdateTrailerType,
  SagaWorker_RefreshParking,
  SagaWorker_ResetParking,
  SagaWorker_GetReportXlsx,
  SagaWorker_DeleteTrailer,
  SagaWorker_GetStationStatuses,
  SagaWorker_CustomRoles,
  SagaWorker_TemplateRoles,
  SagaWorker_CreateStaff,
  SagaWorker_UpdateStaff,
  SagaWorker_ActivateStaff,
  SagaWorker_DeactivateStaff,
  SagaWorker_ChangePassword,
  SagaWorker_RestorePassword,
  SagaWorker_AccessGroups,
  SagaWorker_AccessRules,
  SagaWorker_GetCurrentRole,
  SagaWorker_GetCurrentTrailer,
  SagaWorker_ActivateUser,
  SagaWorker_DeactivateUser,
  SagaWorker_GetBill,
  SagaWorker_RemoveBooking,
  SagaWorker_GetRentsXlsx,
  SagaWorker_GetStaff,
  SagaWorker_GetPositions,
  SagaWorker_UpdateParkingStation,
  SagaWorker_DeleteParking,
  SagaWorker_CompleteRent,
  SagaPage_Organizations,
  SagaPage_OrganizationDetails,
  SagaWorker_ActivateOrganization,
  SagaWorker_DeactivateOrganization,
  SagaWorker_CreateOrganization,
  SagaWorker_UpdateOrganization,
  SagaWorker_GetOrganizationTypes,
  SagaWorker_AddParkingToOrganization,
  SagaWorker_RemoveParkingFromOrganization,
  SagaWorker_LoadAllTrailers,
  SagaWorker_AddTrailerToOrganization,
  SagaWorker_RemoveTrailerFromOrganization,
  SagaWorker_GetDetailedReportXlsx,
  SagaWorker_GetOrganizationsXlsx,
  SagaWorker_CreateStationAndGetParkings,
  SagaWorker_CreateStationAndGetCurrentParking,
  SagaWorker_ActivateTrailerType,
  SagaWorker_DeactivateTrailerType,
  SagaWorker_VerifyUser,
  SagaWorker_UpdateTrailers,
  SagaWorker_GetUsersXlsx,
  SagaWorker_DeleteTrailerType,
  SagaWorker_DeleteStaff,
  SagaWorker_RemoveStaffFromStaffTable,
  SagaWorker_DeleteUser,
  SagaWorker_RemoveUserFromUsersTable,
  SagaWorker_LockStation,
  SagaWorker_UnlockStation,
  SagaWorker_GetSearchResult,
  SagaWorker_GetPromoCode,
  SagaWorker_GeneratePromoCode,
  SagaWorker_CreatePromoCode,
  SagaWorker_UpdatePromoCode,
  SagaWorker_DeletePromoCode,
  SagaWorker_GetNotifications,
  SagaWorker_SetIsRead,
  SagaPage_Breakdowns,
  SagaWorker_UpdateBreakdown,
  SagaWorker_GetCurrentTrailerBreakdowns,
  SagaWorker_UpdateCurrentTrailerBreakdown,
  SagaWorker_GetInvoice,
  SagaWorker_ShowNotificationAndGetNotifications,
  SagaWorker_GetReportCsv,
  SagaWorker_ShowOnMap,
  SagaPage_TrailersList,
  SagaWorker_LoadTrailersList,
  SagaWorker_DeleteTrailerFromTrailersList,
  SagaWorker_GetTrailersListXlsx,
  SagaWorker_GetParkingNotes,
  SagaWorker_CreateParkingNote,
  SagaWorker_DeleteParkingNote,
  SagaWorker_GetTrailerNotes,
  SagaWorker_CreateTrailerNote,
  SagaWorker_DeleteTrailerNote,
  SagaWorker_GetUserNotes,
  SagaWorker_CreateUserNote,
  SagaWorker_DeleteUserNote,
  SagaWorker_GetParentOrganizations,
  SagaWorker_CreateParentOrganization,
  SagaWorker_UpdateParentOrganization,
  SagaWorker_ActivateParentOrganization,
  SagaWorker_DeactivateParentOrganization,
  SagaWorker_GetRegions,
  SagaPage_ParentOrganizations,
  SagaWorker_GetStationQRCode,
  SagaWorker_UpdateUserDiscount,
  SagaWorker_RemoveUserDiscount,
  SagaWorker_CreateOrganizationInvitationCodes,
  SagaWorker_GetOrganizationInvitationCodesXlsx,
  SagaWorker_ConnectToOrganizationDiscount,
  SagaWorker_RemoveOrganizationDiscountConnection,
  SagaWorker_GetAllStations,
} from 'redux/sagas'
import { ActionTypes_Auth } from 'redux/modules/auth/TActions'
import { ActionTypes_CurrentTrailer } from 'redux/modules/currentTrailer/TActions'
import { ActionTypes_Trailers } from 'redux/modules/trailers/TActions'
import { ActionTypes_Report } from 'redux/modules/report/TActions'
import { ActionTypes_Rents } from 'redux/modules/rents/TActions'
import { ActionTypes_Parkings } from 'redux/modules/parkings/TActions'
import { ActionTypes_CurrentParking } from 'redux/modules/currentParking/TActions'
import { ActionTypes_CurrentStation } from 'redux/modules/currentStation/TActions'
import { ActionTypes_Organizations } from 'redux/modules/organizations/TActions'
import { ActionTypes_TrailerTypes } from 'redux/modules/trailerTypes/TActions'
import { ActionTypes_StationStatuses } from 'redux/modules/stationStatuses/TActions'
import { ActionTypes_Staff } from 'redux/modules/staff/TActions'
import { ActionTypes_CustomRoles } from 'redux/modules/customRoles/TActions'
import { ActionTypes_TemplateRoles } from 'redux/modules/templateRoles/TActions'
import { ActionTypes_CurrentStaffMember } from 'redux/modules/currentStaffMember/TAction'
import { ActionTypes_AccessGroups } from 'redux/modules/accessGroups/TAction'
import { ActionTypes_AccessRules } from 'redux/modules/accessRules/TAction'
import { ActionTypes_CurrentRole } from 'redux/modules/currentRole/TAction'
import { ActionTypes_Users } from 'redux/modules/users/TActions'
import { ActionTypes_CurrentUser } from 'redux/modules/currentUser/TAction'
import { ActionTypes_Positions } from 'redux/modules/positions/TActions'
import { ActionTypes_CurrentOrganization } from 'redux/modules/currentOrganization/TAction'
import { ActionTypes_OrganizationTypes } from 'redux/modules/organizationTypes/TActions'
import { ActionTypes_Search } from 'redux/modules/search/TActions'
import { ActionTypes_PromoCode } from 'redux/modules/promoCode/TActions'
import { ActionTypes_NotificationHistory } from 'redux/modules/notificationHistory/TActions'
import { ActionTypes_Notifications } from 'redux/modules/notification/TActions'
import { ActionTypes_Breakdowns } from 'redux/modules/breakdowns/TActions'
import { ActionTypes_CurrentTrailerBreakdowns } from 'redux/modules/currentTrailerBreakdowns/TActions'
import { ActionTypes_ParkingNotes } from 'redux/modules/parkingNotes/TActions'
import { ActionTypes_TrailerNotes } from 'redux/modules/trailerNotes/TActions'
import { ActionTypes_UserNotes } from 'redux/modules/userNotes/TActions'
import { ActionTypes_ParentOrganizations } from 'redux/modules/parentOrganizations/TActions'
import { ActionTypes_Regions } from 'redux/modules/regions/TActions'
import { ActionTypes_Stations } from 'redux/modules/stations/TActions'

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes_Search.SagaEvents.GET_SEARCH_RESULT, SagaWorker_GetSearchResult),

    takeEvery(ActionTypes_Organizations.SagaEvents.GET_ORGANIZATIONS, SagaWorker_Organizations),
    takeEvery(ActionTypes_TemplateRoles.SagaEvents.GET_ROLES, SagaWorker_TemplateRoles),
    takeEvery(ActionTypes_CustomRoles.SagaEvents.GET_ROLES, SagaWorker_CustomRoles),
    takeEvery(ActionTypes_Auth.SagaEvents.GET_INITIAL_DATA, SagaWorker_Initial),

    takeEvery(ActionTypes_Auth.SagaEvents.GET_AUTH_INFO, SagaWorker_Auth),
    takeEvery(ActionTypes_Auth.SagaEvents.CHANGE_PASSWORD, SagaWorker_ChangePassword),

    takeEvery(ActionTypes_Trailers.SagaEvents.GET_TRAILERS, SagaWorker_LoadTrailerMarkers),
    takeEvery(ActionTypes_Trailers.SagaEvents.LOAD_PAGE, SagaPage_TrailersMap),
    takeEvery(ActionTypes_Trailers.SagaEvents.GET_ALL_TRAILERS, SagaWorker_LoadAllTrailers),
    takeEvery(ActionTypes_Trailers.SagaEvents.UPDATE_TRAILERS, SagaWorker_UpdateTrailers),
    takeEvery(ActionTypes_Trailers.SagaEvents.LOAD_TRAILERS_LIST_PAGE, SagaPage_TrailersList),
    takeEvery(ActionTypes_Trailers.SagaEvents.GET_TRAILERS_LIST, SagaWorker_LoadTrailersList),
    takeEvery(
      ActionTypes_Trailers.SagaEvents.DELETE_TRAILER_FROM_TRAILERS_LIST,
      SagaWorker_DeleteTrailerFromTrailersList,
    ),
    takeEvery(ActionTypes_Trailers.SagaEvents.LOAD_TABLE_XLSX, SagaWorker_GetTrailersListXlsx),

    takeEvery(ActionTypes_Breakdowns.SagaEvents.GET_BREAKDOWNS, SagaPage_Breakdowns),
    takeEvery(ActionTypes_Breakdowns.SagaEvents.UPDATE_BREAKDOWN, SagaWorker_UpdateBreakdown),

    takeEvery(ActionTypes_TrailerTypes.SagaEvents.LOAD_PAGE, SagaPage_TrailerTypes),
    takeEvery(ActionTypes_TrailerTypes.SagaEvents.CREATE_TRAILER_TYPE, SagaWorker_CreateTrailerType),
    takeEvery(ActionTypes_TrailerTypes.SagaEvents.UPDATE_TRAILER_TYPE, SagaWorker_UpdateTrailerType),
    takeEvery(ActionTypes_TrailerTypes.SagaEvents.ACTIVATE_TRAILER_TYPE, SagaWorker_ActivateTrailerType),
    takeEvery(ActionTypes_TrailerTypes.SagaEvents.DEACTIVATE_TRAILER_TYPE, SagaWorker_DeactivateTrailerType),
    takeEvery(ActionTypes_TrailerTypes.SagaEvents.DELETE_TRAILER_TYPE, SagaWorker_DeleteTrailerType),

    takeEvery(ActionTypes_Report.SagaEvents.LOAD_PAGE, SagaPage_Report),
    takeEvery(ActionTypes_Report.SagaEvents.LOAD_XLSX, SagaWorker_GetReportXlsx),
    takeEvery(ActionTypes_Report.SagaEvents.LOAD_DETAILED_XLSX, SagaWorker_GetDetailedReportXlsx),
    takeEvery(ActionTypes_Report.SagaEvents.LOAD_CSV, SagaWorker_GetReportCsv),

    takeEvery(ActionTypes_Report.SagaEvents.GET_REPORT, SagaWorker_GetReport),
    takeEvery(ActionTypes_Report.StoreActions.INCREASE_FILTER_OFFSET, SagaWorker_GetReport),

    takeEvery(ActionTypes_Report.SagaEvents.GET_DETAILED_REPORT, SagaWorker_GetDetailedReport),
    takeEvery(ActionTypes_Report.StoreActions.INCREASE_FILTER_OFFSET, SagaWorker_GetDetailedReport),

    takeEvery(ActionTypes_CurrentTrailer.SagaEvents.LOAD_PAGE, SagaPage_TrailerDetails),
    takeEvery(ActionTypes_CurrentTrailer.SagaEvents.GET_CURRENT_TRAILER, SagaWorker_GetCurrentTrailer),
    takeEvery(ActionTypes_CurrentTrailer.SagaEvents.CREATE_TRAILER, SagaWorker_CreateTrailer),
    takeEvery(ActionTypes_CurrentTrailer.SagaEvents.UPDATE_TRAILER, SagaWorker_UpdateTrailer),
    takeEvery(ActionTypes_CurrentTrailer.SagaEvents.DELETE_TRAILER, SagaWorker_DeleteTrailer),
    takeEvery(ActionTypes_CurrentTrailer.SagaEvents.SHOW_ON_MAP, SagaWorker_ShowOnMap),

    takeEvery(
      ActionTypes_CurrentTrailerBreakdowns.SagaEvents.GET_CURRENT_TRAILER_BREAKDOWNS,
      SagaWorker_GetCurrentTrailerBreakdowns,
    ),
    takeEvery(
      ActionTypes_CurrentTrailerBreakdowns.SagaEvents.UPDATE_CURRENT_TRAILER_BREAKDOWN,
      SagaWorker_UpdateCurrentTrailerBreakdown,
    ),

    takeEvery(ActionTypes_Rents.SagaEvents.GET_RENTS, SagaWorker_GetRents),
    takeEvery(ActionTypes_Rents.StoreActions.INCREASE_FILTER_OFFSET, SagaWorker_GetRents),
    takeEvery(ActionTypes_Rents.SagaEvents.LOAD_XLSX, SagaWorker_GetRentsXlsx),

    takeEvery(ActionTypes_Parkings.SagaEvents.GET_PARKINGS, SagaWorker_LoadParkings),
    takeEvery(ActionTypes_Parkings.SagaEvents.GET_ALL_PARKINGS, SagaWorker_LoadAllParkings),
    takeEvery(ActionTypes_Parkings.SagaEvents.LOAD_PAGE, SagaPage_Parkings),
    takeEvery(ActionTypes_Parkings.SagaEvents.DELETE_PARKING, SagaWorker_DeleteParking),
    takeEvery(ActionTypes_Parkings.SagaEvents.CREATE_STATION_AND_GET_PARKINGS, SagaWorker_CreateStationAndGetParkings),
    takeEvery(ActionTypes_Parkings.SagaEvents.RESET_PARKING, SagaWorker_ResetParking),
    takeEvery(ActionTypes_CurrentParking.SagaEvents.CREATE_PARKING, SagaWorker_CreateParking),
    takeEvery(ActionTypes_CurrentParking.SagaEvents.UPDATE_PARKING, SagaWorker_UpdateParking),
    takeEvery(ActionTypes_CurrentParking.SagaEvents.REFRESH_PARKING, SagaWorker_RefreshParking),
    takeEvery(ActionTypes_CurrentParking.SagaEvents.GET_PARKING_DETAILS, SagaPage_ParkingDetails),
    takeEvery(
      ActionTypes_CurrentParking.SagaEvents.CREATE_STATION_AND_GET_PARKING_DETAILS,
      SagaWorker_CreateStationAndGetCurrentParking,
    ),
    takeEvery(ActionTypes_CurrentParking.SagaEvents.UPDATE_PARKING_STATION, SagaWorker_UpdateParkingStation),

    takeEvery(ActionTypes_CurrentStation.SagaEvents.CREATE_STATION, SagaWorker_CreateStation),
    takeEvery(ActionTypes_CurrentStation.SagaEvents.UPDATE_STATION, SagaWorker_UpdateStation),
    takeEvery(ActionTypes_StationStatuses.SagaEvents.GET_STATION_STATUSES, SagaWorker_GetStationStatuses),
    takeEvery(ActionTypes_CurrentStation.SagaEvents.LOCK_STATION, SagaWorker_LockStation),
    takeEvery(ActionTypes_CurrentStation.SagaEvents.UNLOCK_STATION, SagaWorker_UnlockStation),
    takeEvery(ActionTypes_CurrentStation.SagaEvents.GET_QR_CODE, SagaWorker_GetStationQRCode),
    takeEvery(ActionTypes_Stations.SagaEvents.GET_ALL_STATIONS, SagaWorker_GetAllStations),

    takeEvery(ActionTypes_Staff.SagaEvents.LOAD_PAGE, SagaPage_Staff),
    takeEvery(ActionTypes_Staff.SagaEvents.GET_STAFF, SagaWorker_GetStaff),
    takeEvery(ActionTypes_Staff.StoreActions.INCREASE_FILTER_OFFSET, SagaWorker_GetStaff),
    takeEvery(ActionTypes_Staff.SagaEvents.DELETE_STAFF, SagaWorker_RemoveStaffFromStaffTable),

    takeEvery(ActionTypes_CurrentStaffMember.SagaEvents.GET_STAFF_DETAILS, SagaPage_StaffDetails),
    takeEvery(ActionTypes_CurrentStaffMember.SagaEvents.CREATE_STAFF, SagaWorker_CreateStaff),
    takeEvery(ActionTypes_CurrentStaffMember.SagaEvents.UPDATE_STAFF, SagaWorker_UpdateStaff),
    takeEvery(ActionTypes_CurrentStaffMember.SagaEvents.ACTIVATE_STAFF, SagaWorker_ActivateStaff),
    takeEvery(ActionTypes_CurrentStaffMember.SagaEvents.DEACTIVATE_STAFF, SagaWorker_DeactivateStaff),
    takeEvery(ActionTypes_CurrentStaffMember.SagaEvents.DELETE_STAFF, SagaWorker_DeleteStaff),
    takeEvery(ActionTypes_CurrentStaffMember.SagaEvents.RESTORE_PASSWORD, SagaWorker_RestorePassword),

    takeEvery(ActionTypes_AccessGroups.SagaEvents.GET_ACCESS_GROUPS, SagaWorker_AccessGroups),
    takeEvery(ActionTypes_AccessRules.SagaEvents.GET_ACCESS_RULES, SagaWorker_AccessRules),
    takeEvery(ActionTypes_CurrentRole.SagaEvents.GET_CURRENT_ROLE, SagaWorker_GetCurrentRole),

    takeEvery(ActionTypes_Users.SagaEvents.GET_USERS, SagaPage_Users),
    takeEvery(ActionTypes_Users.StoreActions.INCREASE_FILTER_OFFSET, SagaPage_Users),
    takeEvery(ActionTypes_Users.SagaEvents.LOAD_XLSX, SagaWorker_GetUsersXlsx),
    takeEvery(ActionTypes_Users.SagaEvents.DELETE_USER, SagaWorker_RemoveUserFromUsersTable),

    takeEvery(ActionTypes_CurrentUser.SagaEvents.LOAD_PAGE, SagaPage_UserDetails),
    takeEvery(ActionTypes_CurrentUser.SagaEvents.ACTIVATE_USER, SagaWorker_ActivateUser),
    takeEvery(ActionTypes_CurrentUser.SagaEvents.GET_BILL, SagaWorker_GetBill),
    takeEvery(ActionTypes_CurrentUser.SagaEvents.GET_INVOICE, SagaWorker_GetInvoice),
    takeEvery(ActionTypes_CurrentUser.SagaEvents.REMOVE_BOOKING, SagaWorker_RemoveBooking),
    takeEvery(ActionTypes_CurrentUser.SagaEvents.DEACTIVATE_USER, SagaWorker_DeactivateUser),
    takeEvery(ActionTypes_CurrentUser.SagaEvents.COMPLETE_RENT, SagaWorker_CompleteRent),
    takeEvery(ActionTypes_CurrentUser.SagaEvents.VERIFY_USER, SagaWorker_VerifyUser),
    takeEvery(ActionTypes_CurrentUser.SagaEvents.DELETE_USER, SagaWorker_DeleteUser),
    takeEvery(ActionTypes_CurrentUser.SagaEvents.UPDATE_USER_DISCOUNT, SagaWorker_UpdateUserDiscount),
    takeEvery(ActionTypes_CurrentUser.SagaEvents.REMOVE_USER_DISCOUNT, SagaWorker_RemoveUserDiscount),
    takeEvery(
      ActionTypes_CurrentUser.SagaEvents.CONNECT_TO_ORGANIZATION_DISCOUNT,
      SagaWorker_ConnectToOrganizationDiscount,
    ),
    takeEvery(
      ActionTypes_CurrentUser.SagaEvents.REMOVE_ORGANIZATION_DISCOUNT_CONNECTION,
      SagaWorker_RemoveOrganizationDiscountConnection,
    ),

    takeEvery(ActionTypes_Positions.SagaEvents.GET_POSITIONS, SagaWorker_GetPositions),

    takeEvery(ActionTypes_Organizations.SagaEvents.LOAD_PAGE, SagaPage_Organizations),
    takeEvery(ActionTypes_Organizations.SagaEvents.LOAD_XLSX, SagaWorker_GetOrganizationsXlsx),
    takeEvery(ActionTypes_CurrentOrganization.SagaEvents.LOAD_PAGE, SagaPage_OrganizationDetails),
    takeEvery(ActionTypes_CurrentOrganization.SagaEvents.CREATE_ORGANIZATION, SagaWorker_CreateOrganization),
    takeEvery(ActionTypes_CurrentOrganization.SagaEvents.UPDATE_ORGANIZATION, SagaWorker_UpdateOrganization),
    takeEvery(ActionTypes_CurrentOrganization.SagaEvents.ACTIVATE_ORGANIZATION, SagaWorker_ActivateOrganization),
    takeEvery(ActionTypes_CurrentOrganization.SagaEvents.DEACTIVATE_ORGANIZATION, SagaWorker_DeactivateOrganization),
    takeEvery(
      ActionTypes_CurrentOrganization.SagaEvents.ADD_PARKING_TO_ORGANIZATION,
      SagaWorker_AddParkingToOrganization,
    ),
    takeEvery(
      ActionTypes_CurrentOrganization.SagaEvents.ADD_TRAILER_TO_ORGANIZATION,
      SagaWorker_AddTrailerToOrganization,
    ),
    takeEvery(
      ActionTypes_CurrentOrganization.SagaEvents.REMOVE_PARKING_FROM_ORGANIZATION,
      SagaWorker_RemoveParkingFromOrganization,
    ),
    takeEvery(
      ActionTypes_CurrentOrganization.SagaEvents.REMOVE_TRAILER_FROM_ORGANIZATION,
      SagaWorker_RemoveTrailerFromOrganization,
    ),
    takeEvery(
      ActionTypes_CurrentOrganization.SagaEvents.CREATE_ORGANIZATION_INVITATION_CODES,
      SagaWorker_CreateOrganizationInvitationCodes,
    ),
    takeEvery(
      ActionTypes_CurrentOrganization.SagaEvents.LOAD_ORGANIZATION_INVITATION_CODES_XLSX,
      SagaWorker_GetOrganizationInvitationCodesXlsx,
    ),

    takeEvery(ActionTypes_OrganizationTypes.SagaEvents.GET_ORGANIZATION_TYPES, SagaWorker_GetOrganizationTypes),

    takeEvery(ActionTypes_ParentOrganizations.SagaEvents.LOAD_PAGE, SagaPage_ParentOrganizations),
    takeEvery(ActionTypes_ParentOrganizations.SagaEvents.GET_PARENT_ORGANIZATIONS, SagaWorker_GetParentOrganizations),
    takeEvery(
      ActionTypes_ParentOrganizations.SagaEvents.CREATE_PARENT_ORGANIZATION,
      SagaWorker_CreateParentOrganization,
    ),
    takeEvery(
      ActionTypes_ParentOrganizations.SagaEvents.UPDATE_PARENT_ORGANIZATION,
      SagaWorker_UpdateParentOrganization,
    ),
    takeEvery(
      ActionTypes_ParentOrganizations.SagaEvents.ACTIVATE_PARENT_ORGANIZATION,
      SagaWorker_ActivateParentOrganization,
    ),
    takeEvery(
      ActionTypes_ParentOrganizations.SagaEvents.DEACTIVATE_PARENT_ORGANIZATION,
      SagaWorker_DeactivateParentOrganization,
    ),

    takeEvery(ActionTypes_PromoCode.SagaEvents.GET_PROMO_CODE, SagaWorker_GetPromoCode),
    takeEvery(ActionTypes_PromoCode.SagaEvents.GENERATE_PROMO_CODE, SagaWorker_GeneratePromoCode),
    takeEvery(ActionTypes_PromoCode.StoreActions.INCREASE_FILTER_OFFSET, SagaWorker_GetPromoCode),
    takeEvery(ActionTypes_PromoCode.SagaEvents.CREATE_PROMO_CODE, SagaWorker_CreatePromoCode),
    takeEvery(ActionTypes_PromoCode.SagaEvents.UPDATE_PROMO_CODE, SagaWorker_UpdatePromoCode),
    takeEvery(ActionTypes_PromoCode.SagaEvents.DELETE_PROMO_CODE, SagaWorker_DeletePromoCode),

    takeEvery(ActionTypes_NotificationHistory.SagaEvents.GET_NOTIFICATIONS, SagaWorker_GetNotifications),
    takeEvery(
      ActionTypes_NotificationHistory.SagaEvents.SHOW_NOTIFICATION_AND_GET_NOTIFICATIONS,
      SagaWorker_ShowNotificationAndGetNotifications,
    ),
    takeEvery(ActionTypes_NotificationHistory.StoreActions.IS_SHOW_DRAWER, SagaWorker_SetIsRead),

    takeEvery(ActionTypes_ParkingNotes.SagaEvents.GET_PARKING_NOTES, SagaWorker_GetParkingNotes),
    takeEvery(ActionTypes_ParkingNotes.SagaEvents.CREATE_PARKING_NOTE, SagaWorker_CreateParkingNote),
    takeEvery(ActionTypes_ParkingNotes.SagaEvents.DELETE_PARKING_NOTE, SagaWorker_DeleteParkingNote),

    takeEvery(ActionTypes_TrailerNotes.SagaEvents.GET_TRAILER_NOTES, SagaWorker_GetTrailerNotes),
    takeEvery(ActionTypes_TrailerNotes.SagaEvents.CREATE_TRAILER_NOTE, SagaWorker_CreateTrailerNote),
    takeEvery(ActionTypes_TrailerNotes.SagaEvents.DELETE_TRAILER_NOTE, SagaWorker_DeleteTrailerNote),

    takeEvery(ActionTypes_UserNotes.SagaEvents.GET_USER_NOTES, SagaWorker_GetUserNotes),
    takeEvery(ActionTypes_UserNotes.SagaEvents.CREATE_USER_NOTE, SagaWorker_CreateUserNote),
    takeEvery(ActionTypes_UserNotes.SagaEvents.DELETE_USER_NOTE, SagaWorker_DeleteUserNote),

    takeEvery(ActionTypes_Regions.SagaEvents.GET_REGIONS, SagaWorker_GetRegions),
  ])
}
