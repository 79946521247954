import React, { FC } from 'react'
import { useParkingCreate } from './ParkingCreate-ViewModel'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { Form } from 'utils/helpers/form'
import { countryOptions } from 'utils/constants/country'

export const ParkingCreate: FC = () => {
  const vm = useParkingCreate()
  const { closeModal, form, isLoading, organizationOptions, t, regionOptions } = vm
  const { errors, handleSubmit, register, control, formValidation } = form
  return (
    <Shared.Card style={{ width: 375, overflowY: 'auto', maxHeight: '100%' }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Shared.CardTitle title={t('title')} />
            <Shared.InputField
              name="parkingCode"
              title={t('parkingCode')}
              register={register(formValidation.parkingCode)}
              error={errors.parkingCode?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="imei"
              title={t('imei')}
              register={register(Form.required)}
              error={errors.imei?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Dropdown
              name="organizationId"
              label={t('organizationId')}
              options={organizationOptions}
              marginTop={0}
              fullWidth
              control={control}
              rules={Form.required}
              error={errors.organizationId?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Dropdown
              name="country"
              label={t('country')}
              options={countryOptions}
              marginTop={0}
              fullWidth
              control={control}
              error={errors.country?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Dropdown
              name="regionId"
              label={t('region')}
              options={regionOptions}
              marginTop={0}
              fullWidth
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="city"
              title={t('city')}
              register={register(Form.required)}
              error={errors.city?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="street"
              title={t('street')}
              register={register(Form.required)}
              error={errors.street?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="postIndex"
              title={t('postIndex')}
              register={register(Form.required)}
              error={errors.postIndex?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              disabled
              readOnly
              name="coordinatesString"
              title={t('coordinatesString')}
              register={register()}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Button
              style={{ margin: '3.75em 0 0.875em 0' }}
              color={'secondary'}
              title={t('cancel')}
              onClick={closeModal}
            />
            <Shared.Button title={t('submit')} type={'submit'} />
          </Grid>

          {isLoading && <Shared.Preloader mask />}
        </Grid>
      </form>
    </Shared.Card>
  )
}
